import api from '@/services/api'
//import {tracker} from '@/services/tracker';


const store = {
	namespaced: true,
	state: {
		config: {},
		registertoken:null
	},
	mutations: {
		setconfig: (state, config) => {
			state.config = config;
			/*if (['10', 'UT-A5-1-RYD9J', 'UT-A7-C62-Q8749'].indexOf(config.user.uid) !== -1) {
				tracker.start({
					userID: config.user.uid,
					metadata: {
						env: process.env.NODE_ENV
					}
				});
				tracker.setUserID(config.user.uid);
			} else {
				tracker.stop();
			}*/
		},
		SETTOKEN: (state, token) => {
			state.registertoken = token;
		}
	},
	
	actions: {

		// supprime le token en place
		resetRegisterToken(context) {
			context.commit('SETTOKEN', null);
		},
		async pushregistration(context, token) {
			try {
				let call = await api.post({
					url: 'intervention/pushregistration',
					params: {userid: context.state.config.user.uid, registrationid: token}
				})
				context.commit('SETTOKEN', token);
			} catch (err) {
				console.log('error pushregistration', err);
			}
		},
		
		async refresh({commit, state}) {
			try {
				let call = await api.get({url: 'intervention/config'})
				commit('setconfig', call.response)
				console.log('setconfig', call.response)
				
			} catch (err) {
				console.log('forms refresh', err);
			}
		}
		
		
	},
	getters: {
		getConfig: function (state) {
			return state.config;
		},
		getRegisterToken: function(state) {
			return state.registertoken;
		}
	},
	modules: {}
}

export default store