<template>
	<div class="container-fluid" id="content">
		<div class="mt-3 row">
			<div class="col-12">
				<Loader v-model="loading"></Loader>
				<Categories v-if="path === 'categories'" @changePath="updatePath" v-model="category"></Categories>
				<Capture v-else-if="path === 'capture'" v-model="base64Image" @changePath="updatePath"></Capture>
				<DataValidation v-else-if="path === 'dataValidation'" @changePath="updatePath" :fields="ticketDatas"></DataValidation>
				<RecapNote v-else-if="path === 'recapNote'" @changePath="updatePath" @saved="saved"></RecapNote>
			</div>
		</div>
	</div>
</template>

<script>

import Capture from '@/components/ExpensesScan/Capture.vue'
import Categories from '@/components/ExpensesScan/Categories.vue'
import DataValidation from '@/components/ExpensesScan/DataValidation.vue'
import RecapNote from '@/components/ExpensesScan/RecapNote.vue'
import {mapActions, mapGetters} from 'vuex'
import {ADD_TOAST_MESSAGE} from "vuex-toast";
import axios from "axios";
import Loader from "@/components/Loader.vue";
import tmpImg from "@/noteFraisBase64.json";

export default {
	name: 'ExpensesScan',
	components: {Loader, Categories, Capture, DataValidation, RecapNote},
	data() {
		return {
			path: 'categories',
			base64Image: null,
			ticketDatas: null,
			category: null,
			loading: false,
		}
	},
	beforeMount() {
		if (this.$route.params.id) {
			this.loading = 'Chargement';
			const url = null;
			this.getExpense(url).then(() => {
				this.category = this.getCategory;
				this.base64Image = this.getBase64Img;
				this.ticketDatas = this.getTicketDatas;
				this.loading = false;
			});
		}
	},
	computed: {
		...mapGetters('notesFrais', ['getCategory', 'getBase64Img', 'getTicketDatas']),
	},
	methods: {
		...mapActions('notesFrais', ['setBase64Img', 'setCategory', 'setTicketDatas', 'setId']),
		...mapActions({
			addToast: ADD_TOAST_MESSAGE
		}),
		updatePath(path) {
			this.path = path;
		},
		saved(status) {
			if (status === 'success') {
				this.addToast({
					text: 'Note de frais sauvegardée',
					type: 'success',
					dismissAfter: 10000
				});
				return;
			}

			this.addToast({
				text: 'Erreur lors de la sauvegarde',
				type: 'danger',
				dismissAfter: 10000
			});
		},
		getExpense(url) {
			return new Promise((resolve, reject) => {
				if (!url) {
					this.setId(this.$route.params.id);
					this.setCategory('transportation');
					this.setTicketDatas({
						Date: {
							content: '15/06/2024',
							name: 'Date',
						},
						Nom: {
							content: this.config.user.username,
							name: 'Nom',
						},
						FournisseurAdresse: {
							content: 'FournisseurAdresse',
							name: 'Adresse du fournisseur',
						},
						FournisseurName: {
							content: 'FournisseurName',
							name: 'Nom du fournisseur',
						},
						ModePaiement: {
							content: 'ModePaiement',
							name: 'Mode de paiement',
						},
						Pays: {
							content: 'Luxembourg',
							name: 'Luxembourg',
						},
						Raison: {
							content: 'Client',
							name: 'Client',
						},
						TotalTTC: {
							content: '15.30',
							name: 'Total TTC',
						}
					});
					this.setBase64Img(tmpImg.base64);

					resolve();
					return;
				}

				axios.post(url).then(res => {
					this.setId(this.$route.params.id);
					this.setCategory(res['category']);
					this.setTicketDatas(res['datas']);
					this.setBase64Img(res['image'].base64);

					resolve();
				}).catch(e => {
					reject(e);
				});
			});
		},
	}
}
</script>

<style scoped>
#content {
	max-height: 90vh;
}
</style>