<template>
	<div v-if="value" class="background d-flex justify-content-center align-items-center flex-column">
		<div class="spinner"></div>
		<div class="text font-weight-bold font-size-h3 mt-5 user-select-none">{{ value }} . . .</div>
	</div>
</template>
<script>
export default {
	props: {
		value: {
			required: true,
		}
	}
}
</script>
<style scoped>
img {
	width: 100px;
}

.background {
	top:0;
	left:0;
	width: 100vw;
	height: 100vh;
	position: fixed;
	z-index:9999;
	background: #f8f9fc;
}

.spinner {
	height: 80px;
	width: 80px;
	border: 6px solid;
	border-color: #0D5349 transparent #0D5349 transparent;
	border-radius: 50%;
	animation: spin 1.3s linear infinite;
}

.text  {
	color: #0D5349;
}

@keyframes spin {
	to {
		transform: rotate(360deg);
	}
}
</style>