
<template>
	<div class="h-100 row justify-content-center mt-3">
		<div class="col-10">
			<h3>Notes de frais</h3>
			<router-link class="btn btn-block btn-hero-warning" to="/expenses/scan">Scanner une note de frais</router-link>
			<router-link class="btn btn-block btn-hero-warning" to="/expenses/index">Mes notes de frais</router-link>
		</div>
	</div>
</template>

<script>

	export default {
		name: 'Expenses',
		props: [],
		methods: {
			displayExpenses() {
				return null;
			},
			exportExpenses() {
				return null;
			},
		}
	}
</script>

<style>
	.btn .ribbon-box {
		height: 1.75rem;
    	line-height: 1.75rem;
	}
</style>
