const store = {
	namespaced: true,
	state: {
		id: null,
		ticketDatas: {},
		category: null,
		base64Img: null,
	},
	mutations: {
		SET_ID(state, data) {
			state.id = data;
		},
		SET_TICKET_DATA(state, data) {
			state.ticketDatas = data;
		},
		SET_CATEGORY(state, category) {
			state.category = category;
		},
		SET_BASE64_IMG(state, img) {
			state.base64Img = img;
		},
	},
	actions: {
		setId({state, commit}, value) {
			commit('SET_ID', value);
		},
		setCategory({state, commit}, value) {
			commit('SET_CATEGORY', value);
		},
		setTicketDatas({state, commit}, value) {
			commit('SET_TICKET_DATA', value);
		},
		setBase64Img({state, commit}, value) {
			commit('SET_BASE64_IMG', value);
		},
	},
	getters: {
		getId: (state) => {
			return state.id;
		},
		getTicketDatas: (state) => {
			return state.ticketDatas;
		},
		getCategory: (state) => {
			return state.category;
		},
		getBase64Img: (state) => {
			return state.base64Img;
		},
	},
}

export default store