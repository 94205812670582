<template>
	<div class="container-fluid" id="content">
		<div v-if="!expense" class="mt-3 row justify-content-center">
			<div class="col-10">
				<div class="d-flex justify-content-between mb-3">
					<router-link class="btn btn-secondary" to="/expenses"><i class="fas fa-arrow-left"></i> Retour</router-link>
				</div>
				<h5 class="card-header">
					Mes notes de frais
				</h5>
				<div class="card-body">
					<div class="row">
						<div class="col">
							<div class="form-group">
								<label for="start">Mois :</label>
								<input type="month" v-model="params.date" class="form-control"/>
							</div>
						</div>
					</div>
					<nav class="nav nav-tabs nav-tabs-alt nav-justified">
						<a v-for="category in categories" class="text-sm-center nav-link nav-item"
						   :class="{'active': category===params.category}" @click.prevent="params.category = category"
						   href="#">{{ $t(category) }}</a>
					</nav>

					<div class="block-content block-content-full p-0 tab-content">
						<div class="tab-pane active">
							<button v-for="e in expenses" type="button" class="list-group-item list-group-item-action d-flex justify-content-between"
									role="button" @click.prevent="expense = e">
								<div>
									<span class="font-weight-bold">{{ e.datas.Nom.content }}</span>
									<span v-if="params.category !== 'fuel'"> - {{ e.datas.FournisseurName.content }}</span>
								</div>
								<div class="font-weight-bold">{{ e.datas.Date.content }}</div>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-else-if="!displayImage" class="row justify-content-center mt-3">
			<div class="col-10">
				<div class="d-flex justify-content-end mb-3">
					<button @click.prevent="displayImage = true" class="btn btn-info"><i class="fas fa-images"></i>
						Afficher le scan
					</button>
				</div>
				<RecapNote :expense="expense"></RecapNote>
				<div class="d-flex justify-content-between mt-3">
					<button @click.prevent="expense = null" class="btn btn-secondary"><i class="fas fa-arrow-left"></i>
						Retour
					</button>
					<router-link class="btn btn-warning" :to="`/expenses/scan/${expense.id}`"><i
						class="fas fa-edit"></i> Editer
					</router-link>
				</div>
			</div>
		</div>
		<div v-else class="d-flex justify-content-center mt-3 flex-column align-items-center">
			<img id="capture" :src="expense.image.base64" alt="scan">
			<button @click.prevent="displayImage = false" class="btn btn-secondary mt-2">
				<i class="fas fa-arrow-left"></i> Retour
			</button>
		</div>
	</div>
</template>


<script>
import RecapNote from '@/components/ExpensesScan/RecapNote.vue'
import tmpImg from '/src/noteFraisBase64.json';
import moment from 'moment';

moment.locale('fr');
import VueHtml2pdf from 'vue-html2pdf';
import {mapGetters} from "vuex";
import ListOneEvent from "@/components/ListOneEvent.vue";
import ListEvents from "@/components/ListEvents.vue";

export default {
	name: 'ExpensesScan',
	components: {ListEvents, ListOneEvent, RecapNote, VueHtml2pdf},
	data() {
		return {
			params: {
				category: 'fuel',
				date: null,
			},
			rawExpenses: {},
			expense: null,
			displayImage: false,
			categories: [
				'fuel',
				'restaurant',
				'transportation',
				'misc',
			],
		}
	},
	beforeMount() {
		this.params.date = moment().format('YYYY-MM');
		this.rawExpenses = this.getExpenses();
	},
	computed: {
		...mapGetters('config', {'config': 'getConfig'}),
		expenses() {
			return this.rawExpenses.filter(e => {
				let date = moment(e.datas.Date.content, 'DD/MM/YYYY').format('YYYY-MM-DD');
				date = date.substring(0, date.length - 3);
				return e.category === this.params.category && date === this.params.date && (e.datas.Nom.content === this.config.user.username);
			});
		},
		exportDate() {
			return moment(this.params.date).format('MMMM YYYY');
		},
		totalTTC() {
			return this.expenses.reduce((acc, e) => acc + parseFloat(e.datas.TotalTTC.content), 0).toFixed(2);
		},
		users() {
			return [...new Set(this.rawExpenses.map(e => e.datas.Nom.content))];
		}
	},
	methods: {
		getExpenses() {
			return [
				{
					id: 1,
					image: tmpImg,
					category: 'transportation',
					datas: {
						Nom: {
							content: this.config.user.username,
							name: 'Nom',
						},
						Date: {
							content: '15/06/2024',
							name: 'Date',
						},
						FournisseurAdresse: {
							content: 'FournisseurAdresse',
							name: 'Adresse du fournisseur',
						},
						FournisseurName: {
							content: 'FournisseurName',
							name: 'Nom du fournisseur',
						},
						ModePaiement: {
							content: 'ModePaiement',
							name: 'Mode de paiement',
						},
						Pays: {
							content: 'Luxembourg',
							name: 'Luxembourg',
						},
						Raison: {
							content: 'Client',
							name: 'Client',
						},
						TotalTTC: {
							content: '15.30',
							name: 'Total TTC',
						}
					}
				},
				{
					id: 2,
					image: tmpImg,
					category: 'transportation',
					datas: {
						Nom: {
							content: 'Dupont Jean',
							name: 'Nom',
						},
						Date: {
							content: '15/06/2024',
							name: 'Date',
						},
						FournisseurAdresse: {
							content: 'FournisseurAdresse',
							name: 'Adresse du fournisseur',
						},
						FournisseurName: {
							content: 'FournisseurName',
							name: 'Nom du fournisseur',
						},
						ModePaiement: {
							content: 'ModePaiement',
							name: 'Mode de paiement',
						},
						Pays: {
							content: 'Luxembourg',
							name: 'Luxembourg',
						},
						Raison: {
							content: 'Client',
							name: 'Client',
						},
						TotalTTC: {
							content: '15.30',
							name: 'Total TTC',
						}
					}
				},
				{
					id: 3,
					image: tmpImg,
					category: 'fuel',
					datas: {
						Nom: {
							content: this.config.user.username,
							name: 'Nom',
						},
						Date: {
							content: '15/06/2024',
							name: 'Date',
						},
						FournisseurAdresse: {
							content: 'FournisseurAdresse',
							name: 'Adresse du fournisseur',
						},
						Litre: {
							content: '65',
							name: 'Litre',
						},
						Km: {
							content: '650',
							name: 'Km',
						},
						ModePaiement: {
							content: 'ModePaiement',
							name: 'Mode de paiement',
						},
						Pays: {
							content: 'Luxembourg',
							name: 'Luxembourg',
						},
						Raison: {
							content: 'Client',
							name: 'Client',
						},
						TotalTTC: {
							content: '15.30',
							name: 'Total TTC',
						}
					}
				}
			];
			const url = '';
			axios.get(url, {
				params: this.params
			});
		},
	}
}
</script>

<style scoped>
#content {
	max-height: 90vh;
}

#capture {
	margin: 0 auto;
	height: 80vh;
}
</style>