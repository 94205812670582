import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import Login from '@/views/Login.vue'
import UpdatePassword from '@/views/UpdatePassword.vue'
import Event from '@/views/Event.vue'
import Expenses from '@/views/Expenses.vue'
import ExpensesScan from '@/views/ExpensesScan.vue'
import ResultMission from '@/views/ResultMission.vue'
import ExpensesIndex from '@/views/ExpensesIndex.vue'
import store from '@/store/index';

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      title: 'Login',
      logLayout: true,
    },
  },
  {
    path: '/password',
    name: 'updatepassword',
    component: UpdatePassword,
    meta: {
      title: 'Définition de mot de passe',
      logLayout: true,
    },
  },
  {
    path: '/',
    name: 'home',
    meta: {
      title: 'Accueil',
      logLayout: false,
    },
    component: Home
  },
  {
    path: '/event/:eventid',
    name: 'event',
    meta: {
      title: 'Rapport d\'intervention',
      logLayout: false,
    },
    component: Event,
    props: true
  },
  {
    path: '/expenses',
    name: 'expenses',
    component: Expenses,
    meta: {
      logLayout: false
    }
  },
  {
    path: '/expenses/scan/:id?',
    component: ExpensesScan,
    meta: {
      logLayout: false
    }
  },
  {
    path: '/expenses/index',
    component: ExpensesIndex,
    meta: {
      logLayout: false
    }
  },
  {
    path: '/resultMission/:eventid/:responseid/:page?',
    name: 'resultMission',
    component: ResultMission,
    props: true,
    meta: {
      logLayout: false
    }
  },
  {
    path: '/logs',
    name: 'logs',
    meta: {
      title: 'Logs',
      logLayout: false,
    },
    component: () => import(/* webpackChunkName: "logs" */ '@/views/Logs.vue')

  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

const waitForStorageToBeReady = async (to, from, next) => {
  console.log('waitForStorageToBeReady');
  await store.restored
  next()
}
router.beforeEach(waitForStorageToBeReady)


router.beforeEach((to, from, next) => {
  const token = store.getters['login/getToken'];
  const config = store.getters['config/getConfig'];

  if (token === null && to.name !== 'login') {
      next({'name': 'login', replace: true});
  } else if (to.name !== 'updatepassword' && token !== null && config.needchangepassword) {
    next({'name': 'updatepassword', replace: true});
  }
  else if (to.name === 'login' && token !== null ) {
       next({'name': 'home', replace: true});
  }else{
    // on cache le loader qui est dans le header
    jQuery('#page-header-loader').removeClass('show');
    next();
  }
});

export default router
