<template>
	<div>
		<Loader v-model="loading"></Loader>
		<div class="row justify-content-center mb-3">
			<div class="col-10">
				<div class="d-flex justify-content-between mb-3">
					<button class="btn btn-secondary" type="button" @click.prevent="back()">
						<i class="fas fa-arrow-left"></i> Retour
					</button>
					<button v-if="ticketDatas" class="btn btn-success" type="button"
							@click.prevent="validate()">
						<i class="fas fa-check"></i> Valider les données
					</button>
					<button v-else id="process" @click.prevent="process()" class="btn btn-success">
						<i class="fas fa-cogs"></i> Traiter
					</button>
				</div>
				<div class="d-flex flex-column justify-content-center align-items-center pb-5">
					<canvas id="imgSrc" ref="imgSrc"></canvas>
					<div v-if="ticketDatas" id="accordion" class="d-flex justify-content-center flex-column">
						<button class="btn btn-primary" type="button" data-toggle="collapse"
								data-target="#collapseDatas"
								aria-expanded="false" aria-controls="collapseDatas"
								@click.prevent="dataHidden = !dataHidden">
							<i class="fas" :class="{'fa-arrow-down': dataHidden, 'fa-arrow-up': !dataHidden}"></i>
							Données extraites <i class="fas"
												 :class="{'fa-arrow-down': dataHidden, 'fa-arrow-up': !dataHidden}"></i>
						</button>
						<div class="collapse bg-white" id="collapseDatas">
							<div v-for="[key, data] in Object.entries(ticketDatas)">
								<button type="button" class="list-group-item list-group-item-action" role="button"
										:ref="key" @click.prevent="toEdit = key">
									<span class="font-weight-bold">{{ data.name }} : </span>
									<input v-if="toEdit === key && key !== 'ModePaiement'" type="text"
										   v-model="data.content">
									<select v-else-if="toEdit === key" v-model="data.content">
										<option value="CB société">CB société</option>
										<option value="CB perso">CB perso</option>
										<option value="Prélèvement automatique perso">Prélèvement automatique perso
										</option>
										<option value="Prélèvement automatique société">Prélèvement automatique
											société
										</option>
									</select>
									<span v-else>{{ libelle(data.content) }}</span>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import axios from "axios";
import Loader from "@/components/Loader.vue";
import tmpDatas from '/src/noteFraisFields.json';
import {mapActions, mapGetters} from "vuex";

export default {
	components: {
		Loader
	},
	props: {
		fields: {
			required: false,
		}
	},
	data() {
		return {
			base64Image: null,
			dataValidated: false,
			showDatas: false,
			loading: false,
			ticketDatas: null,
			toEdit: null,
			restaurantFields: ['Nom', 'Date', 'FournisseurAdresse', 'Pays', 'FournisseurName', 'Raison', 'TotalTTC', 'ModePaiement'],
			fuelFields: ['Nom', 'Date', 'FournisseurAdresse', 'Pays', 'TotalTTC', 'ModePaiement', 'Km', 'Litre'],
			transportationFields: ['Nom', 'Date', 'FournisseurAdresse', 'Pays', 'FournisseurName', 'Raison', 'TotalTTC'],
			miscFields: ['Nom', 'Date', 'FournisseurAdresse', 'Pays', 'Raison', 'TotalTTC', 'ModePaiement'],
			dataHidden: true,
		}
	},
	beforeMount() {
		if (this.$route.params.id) {
			this.ticketDatas = this.getTicketDatas;
		}
	},
	mounted() {
		this.base64Image = this.getBase64Img;
		let canvas = this.$refs.imgSrc;
		let ctx = canvas.getContext("2d");
		let img = new Image();
		img.onload = () => {
			canvas.width = img.width;
			canvas.height = img.height;
			ctx.drawImage(img, 0, 0);
		};
		img.src = this.base64Image;
	},
	computed: {
		...mapGetters('notesFrais', [
			'getBase64Img',
			'getCategory',
			'getTicketDatas',
		]),
		...mapGetters('config', {'config': 'getConfig'}),
	},
	methods: {
		draw(coordinates) {
			let canvas = this.$refs.imgSrc;
			let ctx = canvas.getContext("2d");

			ctx.fillStyle = 'rgba(255,242,19,0.2)';
			ctx.beginPath();
			ctx.moveTo(coordinates[0], coordinates[1]);
			ctx.lineTo(coordinates[2], coordinates[3]);
			ctx.lineTo(coordinates[4], coordinates[5]);
			ctx.lineTo(coordinates[6], coordinates[7]);
			ctx.closePath();
			ctx.fill();
		},
		getFields() {
			return new Promise((resolve, reject) => {
				//capture auto
				//resolve(tmpDatas.data);
				//return;

				//convert the canvas exportedPic to a base64 picture
				let imgData = this.base64Image.replace("data:image/png;base64,", "");
				axios.post("https://franc1.cognitiveservices.azure.com/formrecognizer/documentModels/inv13-rec7:analyze?api-version=2023-07-31&locale=fr", {base64Source: imgData}, {
					'headers': {
						'Ocp-Apim-Subscription-Key': '5d4cafe974264f7a8955cd2b94b954fa',
						'Content-Type': 'application/json',
					},
				}).then(res => {
					const url = res.headers['operation-location'];
					const interval = setInterval(() => {
						axios.get(url, {
							'headers': {
								'Ocp-Apim-Subscription-Key': '5d4cafe974264f7a8955cd2b94b954fa',
								'Content-Type': 'application/json',
							},
						}).then(res => {
							let response = res.data;
							if (response.status === 'succeeded') {
								clearInterval(interval);
								if (!response.analyzeResult.documents[0]) {
									reject();
									return;
								}

								const fields = response.analyzeResult.documents[0].fields;
								resolve(fields);
							}
						});
					}, 2000);
				});
			});
		},
		parseFields(fields) {
			let fieldsTemplate = [];

			switch (this.getCategory) {
				case 'restaurant':
					fieldsTemplate = this.restaurantFields;
					break;
				case 'fuel':
					fieldsTemplate = this.fuelFields;
					break;
				case 'transportation':
					fieldsTemplate = this.transportationFields;
					break;
				default:
					fieldsTemplate = this.miscFields;
					break;
			}

			return fieldsTemplate.reduce((acc, curr) => {
				acc[curr] = {
					name: this.libelle(curr),
					content: fields[curr] ? fields[curr].content : this.default(curr),
					coordinates: fields[curr] ? fields[curr].boundingRegions[0].polygon : null,
				};

				if (fields[curr]) {
					this.draw(fields[curr].boundingRegions[0].polygon);
				}

				return acc;
			}, {});
		},
		process() {
			this.loading = 'Chargement';

			this.getFields().then(fields => {
				this.ticketDatas = this.parseFields(fields);

				if (!this.ticketDatas?.Nom?.content) {
					this.ticketDatas.Nom = {
						name: 'Nom',
						content: this.config.user.username,
					}
				}

				this.$store.dispatch('notesFrais/setTicketDatas', this.ticketDatas);
				this.$emit('input', fields);
				this.loading = false;
			}).catch(e => {
				this.loading = false;
			});
		},
		libelle(name) {
			switch (name) {
				case 'Date':
					return 'Date';
				case 'FournisseurAdresse':
					return 'Adresse du fournisseur';
				case 'Pays':
					return 'Pays';
				case 'FournisseurName':
					return 'Nom du fournisseur';
				case 'Raison':
					return 'Raison';
				case 'TotalTTC':
					return 'Total TTC';
				case 'ModePaiement':
					return 'Mode de paiement';
				default:
					return name;
			}
		},
		default(name) {
			switch (name) {
				case 'Pays':
					return 'Luxembourg';
				case 'Raison':
					return '';
				case 'ModePaiement':
					return 'CB Société';
				default:
					return '';
			}
		},
		validate() {
			this.$emit('changePath', 'recapNote');
		},
		back() {
			this.$emit('changePath', 'capture');
		}
	}
}

</script>
<style scoped>

#accordion {
	width: 100%;
	position: absolute;
	bottom: 0;
}

#imgSrc {
	max-height: 70vh;
}

#toogleButton {
	background-color: #4CAF50;
	color: white;
	padding: 10px 20px;
	border: none;
	cursor: pointer;
	border-radius: 5px;
	margin-bottom: 10px;
}

#datasBlock {
	display: none;
	bottom: -50%; /* Hide the menu initially */
	background-color: #f9f9f9;
	padding: 10px;
	height: 25%;
	overflow-y: scroll;
	transition: bottom 0.3s ease; /* Add a smooth transition effect */
	z-index: 1;

	&.visible {
		display: flex;
		flex: 0 0 100%;
		flex-direction: column;
		bottom: 0;
	}
}
</style>