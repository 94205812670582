<template>
	<div>
		<Loader v-model="loading"></Loader>
		<div class="row justify-content-center">
			<div class="col-10">
				<div class="d-flex justify-content-between">
					<button class="btn btn-secondary" type="button" @click.prevent="back()">
						<i class="fas fa-arrow-left"></i> Retour
					</button>
				</div>
				<div v-show="displayCamera" ref="photo">
					<video ref="video" id="video"></video>
					<canvas ref="canvas" id="canvas"></canvas>

					<canvas ref="result" id="result"></canvas>
					<div class="photo-button" @click.prevent="extract()">
						<div class="circle"></div>
						<div class="ring"></div>
					</div>
				</div>

				<div class="d-flex justify-content-center mt-4">
					<canvas v-show="extracted" ref="exportedPic" class="w-75"></canvas>
				</div>

<!--				<button v-if="!extracted" @click.prevent="captureAuto()" class="btn btn-success">
					Capture auto (temp)
				</button>-->
				<button v-if="!extracted" id="capture" @click.prevent="requestFullScreen()" class="btn btn-success">
					Capturer
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@/components/Loader.vue";
import jscanify from "jscanify/src/jscanify.js";
import tmpImg from '/src/noteFraisBase64.json';

export default {
	components: {
		Loader
	},
	props: {
		value: {
			required: true,
		}
	},
	data() {
		return {
			width: window.innerWidth,
			height: window.innerHeight,
			result: null,
			displayCamera: false,
			extracted: false,
			loading: false,
			scanner: null,
			canvasCtx: null,
			resultCtx: null,
			videoInterval: null,
		}
	},
	mounted() {
		this.scanner = new jscanify();
		this.canvasCtx = this.$refs.canvas.getContext("2d");
		this.resultCtx = this.$refs.result.getContext("2d");
		if (this.value) {
			this.extracted = true;
		}
	},
	methods: {
		captureAuto() {
			this.$emit('changePath', 'dataValidation');
			this.$store.dispatch('notesFrais/setBase64Img', tmpImg.base64);
		},
		requestFullScreen() {
			this.fullscreen();
			navigator.mediaDevices.getUserMedia({
				audio: false,
				video: {
					facingMode: "environment",
					height: this.width,
					width: this.height,
				}
			}).then((stream) => {
				this.$refs.video.srcObject = stream;
				this.$refs.video.onloadedmetadata = () => {
					this.$refs.video.play();

					// Adjust canvas size to match video aspect ratio
					this.$refs.canvas.width = this.$refs.video.videoWidth;
					this.$refs.canvas.height = this.$refs.video.videoHeight;
					this.$refs.result.width = this.$refs.video.videoWidth;
					this.$refs.result.height = this.$refs.video.videoHeight;

					this.videoInterval = setInterval(() => {
						this.canvasCtx.drawImage(this.$refs.video, 0, 0);
						const resultCanvas = this.scanner.highlightPaper(this.$refs.canvas);
						this.resultCtx.drawImage(resultCanvas, 0, 0);
					}, 10);
				};
			});
		},
		extract() {
			clearInterval(this.videoInterval);
			this.extracted = true;
			this.$refs.exportedPic.width = this.$refs.result.width;
			this.$refs.exportedPic.height = this.$refs.result.height;

			let pic = this.scanner.extractPaper(this.$refs.canvas, this.$refs.result.width, this.$refs.result.height);

			let destCtx = this.$refs.exportedPic.getContext('2d');
			destCtx.drawImage(pic, 0, 0);
			this.$emit('input', this.$refs.exportedPic.toDataURL("image/png"));
			this.fullscreen();
			this.$emit('changePath', 'dataValidation');
			this.$store.dispatch('notesFrais/setBase64Img', this.$refs.exportedPic.toDataURL("image/png"));
		},
		fullscreen() {
			let isInFullScreen = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement;

			let docElm = this.$refs.photo;
			this.displayCamera = !isInFullScreen;

			if (!isInFullScreen) {
				if (docElm.requestFullscreen) {
					docElm.requestFullscreen();
				} else if (docElm.mozRequestFullScreen) {
					docElm.mozRequestFullScreen();
				} else if (docElm.webkitRequestFullScreen) {
					docElm.webkitRequestFullScreen();
				} else if (docElm.msRequestFullscreen) {
					docElm.msRequestFullscreen();
				}
			} else {
				if (document.exitFullscreen) {
					document.exitFullscreen();
				} else if (document.webkitExitFullscreen) {
					document.webkitExitFullscreen();
				} else if (document.mozCancelFullScreen) {
					document.mozCancelFullScreen();
				} else if (document.msExitFullscreen) {
					document.msExitFullscreen();
				}
			}
		},
		back() {
			this.$emit('changePath', 'categories');
		},
	}
}

</script>

<style scoped>
#capture {
	position: absolute;
	top: 50%;
	bottom: 50%;
	right: 0;
	left: 0;
	margin: 0 auto;
	width: 100px;
	height: 50px;
}

#video {
	display: none;
}

#canvas {
	display: none;
}

#photo {
	position: relative;
}

#extract {
	position: absolute;
	bottom: 15px;
	right: 0;
	left: 0;
}

.photo-button {
	width: 100px;
	height: 100px;
	bottom: 20%;
	left: 50%;
	margin-top: -50px;
	margin-left: -50px;
	position: absolute;
}

.circle {
	position: absolute;
	top: 12%;
	left: 12%;
	bottom: 12%;
	right: 12%;
	border-radius: 100%;
	background-color: #ffffff;
	opacity: 0;
}

.ring {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;

	border-radius: 100%;
	border: 0.5em solid #ffffff;
	opacity: 0.8;
}

.photo-button .circle, .photo-button .ring {
	transition: all 0.25s;
}

.photo-button:hover .circle {
	opacity: 1;
}

.photo-button:active .ring {
	opacity: 1;
}

.photo-button:active .circle {
	opacity: 0.5;
}
</style>