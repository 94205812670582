<template>
	<div>
		<div class="row justify-content-center">
			<div class="col-10">
				<div class="d-flex justify-content-between mb-3">
					<router-link class="btn btn-secondary" to="/expenses"><i class="fas fa-arrow-left"></i> Retour</router-link>
				</div>
				<div class="card border-secondary">
					<h5 class="card-header">
						Catégoriser la note de frais
					</h5>
					<div class="card-body">
						<button type="button" class="list-group-item list-group-item-action"
								:class="{'active': value === 'fuel'}"
								@click.prevent="updateCategory('fuel')">
							<i class="fas fa-gas-pump"></i> <span class="font-weight-bold">Carburant</span>
						</button>
						<button type="button" class="list-group-item list-group-item-action"
								:class="{'active': value === 'restaurant'}"
								@click.prevent="updateCategory('restaurant')">
							<i class="fas fa-utensils-alt"></i> <span class="font-weight-bold">Restaurant client</span>
						</button>
						<button type="button" class="list-group-item list-group-item-action"
								:class="{'active': value === 'transportation'}"
								@click.prevent="updateCategory('transportation')">
							<i class="fas fa-subway"></i> <span class="font-weight-bold">Transport</span>
						</button>
						<button type="button" class="list-group-item list-group-item-action"
								:class="{'active': value === 'misc'}"
								@click.prevent="updateCategory('misc')">
							<i class="fas fa-money-check"></i> <span class="font-weight-bold">Divers</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Categories',
	props: {
		value: {
			type: String,
			required: false
		}
	},
	methods: {
		updateCategory(category) {
			this.$store.dispatch('notesFrais/setCategory', category);

			if (this.$route.params.id) {
				this.$emit('changePath', 'dataValidation');
			} else {
				this.$emit('changePath', 'capture');
			}

			this.$emit('input', category);
		}
	}
}
</script>


<style scoped>

</style>