<template>
	<div class="row justify-content-center">
		<div class="col-12">
			<div class="d-flex">
				<button v-if="!expense" class="btn btn-secondary" type="button" @click.prevent="back()">
					<i class="fas fa-arrow-left"></i> Retour
				</button>
			</div>
			<div v-if="ticketDatas" class="card">
				<h3 class="card-header text-center">
					{{ ticketDatas.TotalTTC.content }} €
				</h3>
				<div class="card-body">
					<div class="row">
						<div class="col-6">
							<p class="font-weight-bold font-size-sm bg-light p-2 rounded text-center">
								<i class="fas fa-calendar-alt"></i> {{ ticketDatas.Date.content }}</p>
						</div>
						<div class="col-6">
							<div v-if="category === 'fuel'" class="bg-light p-2 rounded text-center">
								<i class="fas fa-gas-pump"></i> <span
								class="font-weight-bold font-size-sm">Carburant</span>
							</div>
							<div v-if="category === 'restaurant'" class="bg-light p-2 rounded  text-center">
								<i class="fas fa-utensils-alt"></i> <span class="font-weight-bold font-size-sm">Restaurant
								client</span>
							</div>
							<div v-if="category === 'transportation'" class="bg-light p-2 rounded  text-center">
								<i class="fas fa-subway"></i> <span
								class="font-weight-bold font-size-sm">Transport</span>
							</div>
							<div v-if="category === 'misc'" class="bg-light p-2 rounded  text-center">
								<i class="fas fa-money-check"></i> <span
								class="font-weight-bold font-size-sm">Divers</span>
							</div>
						</div>
					</div>
					<div v-for="[key, data] in ticketListItems" v-if="key !== 'Category'">
						<div class="list-group-item">
							<span class="font-weight-bold">{{ data.name }} : </span>{{ data.content }}
						</div>
					</div>
					<div v-if="!expense" class="d-flex justify-content-center mt-3">
						<button @click.prevent="persist()" class="btn btn-success"><i class="fas fa-save"></i> Sauvegarder</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@/components/Loader.vue";
import {mapActions, mapGetters} from "vuex";
import axios from "axios";

export default {
	name: 'RecapNote',
	components: {
		Loader
	},
	props: {
		expense: {
			default: null,
		},
		image: String,
	},
	data() {
		return {
			category: null,
			ticketDatas: null,
		}
	},
	beforeMount() {
		if(this.expense) {
			this.category = this.expense.category;
			this.ticketDatas = this.expense.datas;
		}
		else {
			this.category = this.getCategory;
			this.ticketDatas = this.getTicketDatas;
		}
	},
	computed: {
		...mapGetters('notesFrais', [
			'getCategory',
			'getTicketDatas'
		]),
		ticketListItems() {
			const toFilter = ['Date', 'TotalTTC'];
			return Object.entries(this.ticketDatas).filter(([key, value]) => !toFilter.includes(key));
		}
	},
	methods: {
		persist() {
			const url = null;
			this.store(url).then(res => {
				this.$emit('saved', 'success');
				this.$router.push('/expenses');
			}).catch(e => {
				this.$emit('saved', 'failure');
				console.error(e);
			});
		},
		store(url) {
			return new Promise((resolve, reject) => {
				if (!url) {
					resolve();
					return;
				}
				const expense = {
					id: this.$route.params.id,
					image: this.image,
					category: this.category,
					datas: this.ticketDatas,
				};

				axios.post(url, expense).then(res => {
					resolve(res);
				}).catch(e => {
					reject(e);
				});
			});
		},
		back() {
			this.$emit('changePath', 'dataValidation');
		}
	}
}

</script>

<style scoped>

</style>